import React from 'react';
import { Product } from '../types/index';
import { getProductById } from '../services/firebase';

interface ProductDetailsProps {

    id: string;

}


const ProductDetails: React.FC<ProductDetailsProps> = ({ id }) => {
    const [product, setProduct] = React.useState<Product | null>(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        const fetchProduct = async () => {
            if (id) {
                const fetchedProduct = await getProductById(id);
                setProduct(fetchedProduct || null);
            }
            setLoading(false);
        };

        fetchProduct();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!product) {
        return <div>Product not found</div>;
    }

    return (
        <div>
            <h1>{product.name}</h1>
            <p>{product.description}</p>
            <p>Price: ${product.price}</p>
        </div>
    );
};

export default ProductDetails;