import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box, CircularProgress, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { getProducts, addProduct, updateProduct, deleteProduct } from '../services/firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Product } from '../types/index';

const AdminDashboard: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productImages, setProductImages] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);
  const [editingProductId, setEditingProductId] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [productToDelete, setProductToDelete] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        navigate('/login');
      }
    });

    const fetchProducts = async () => {
      const fetchedProducts = await getProducts();
      setProducts(fetchedProducts);
    };

    fetchProducts();
  }, [navigate]);

  const handleAddOrUpdateProduct = async () => {
    if (!user) {
      alert('You must be logged in to add or update a product.');
      return;
    }

    if (productName && productPrice && productDescription && productImages.length > 0) {
      setUploading(true);
      const storage = getStorage();
      const imageUrls: string[] = [];

      for (const image of productImages) {
        const storageRef = ref(storage, `products/${image.name}`);
        await uploadBytes(storageRef, image);
        const imageUrl = await getDownloadURL(storageRef);
        imageUrls.push(imageUrl);
      }

      if (editingProductId) {
        await updateProduct(editingProductId, {
          name: productName,
          price: parseFloat(productPrice),
          description: productDescription,
          imageUrls: imageUrls,
        });
        setEditingProductId(null);
      } else {
        await addProduct({
          name: productName,
          price: parseFloat(productPrice),
          description: productDescription,
          imageUrls: imageUrls,
        });
      }

      setProductName('');
      setProductPrice('');
      setProductDescription('');
      setProductImages([]);
      setUploading(false);
      alert('Product saved successfully!');
      const fetchedProducts = await getProducts();
      setProducts(fetchedProducts);
    } else {
      alert('Please fill in all fields.');
    }
  };

  const handleEditProduct = (product: Product) => {
    setProductName(product.name);
    setProductPrice(product.price.toString());
    setProductDescription(product.description);
    setProductImages([]);
    setEditingProductId(product.id);
  };

  const handleDeleteProduct = async () => {
    if (productToDelete) {
      await deleteProduct(productToDelete);
      const fetchedProducts = await getProducts();
      setProducts(fetchedProducts);
      setProductToDelete(null);
      setOpenDialog(false);
    }
  };

  const handleOpenDialog = (id: string) => {
    setProductToDelete(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setProductToDelete(null);
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Admin Dashboard
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          {editingProductId ? 'Edit Product' : 'Add New Product'}
        </Typography>
        <TextField
          fullWidth
          label="Product Name"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Product Price"
          value={productPrice}
          onChange={(e) => setProductPrice(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Product Description"
          value={productDescription}
          onChange={(e) => setProductDescription(e.target.value)}
          margin="normal"
          multiline
          rows={4}
        />
        <Button
          variant="contained"
          component="label"
          sx={{ mt: 2 }}
        >
          Upload Images
          <input
            type="file"
            hidden
            multiple
            onChange={(e) => setProductImages(e.target.files ? Array.from(e.target.files) : [])}
          />
        </Button>
        {productImages.length > 0 && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            {productImages.map((image) => image.name).join(', ')}
          </Typography>
        )}
        <Box sx={{ mt: 2, position: 'relative' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddOrUpdateProduct}
            disabled={uploading}
          >
            {uploading ? 'Uploading...' : editingProductId ? 'Update Product' : 'Add Product'}
          </Button>
          {uploading && (
            <CircularProgress
              size={24}
              sx={{
                color: 'primary',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
        <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
          Product List
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product.id}>
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.price}</TableCell>
                <TableCell>{product.description}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditProduct(product)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleOpenDialog(product.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this product? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteProduct} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminDashboard;