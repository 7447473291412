import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getProducts } from '../services/firebase';
import { Product } from '../types/index';
import { Card, CardContent, Typography, CircularProgress, Grid} from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ProductList: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const fetchedProducts = await getProducts();
        setProducts(fetchedProducts);
      } catch (error) {
        console.error("Error fetching products: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={3} >
      {products.map(product => (
        <Grid item xs={6} sm={4} md={3} key={product.id}>
          <Card>
            <Carousel showThumbs={true} showStatus={true} infiniteLoop>
              {product.imageUrls && product.imageUrls.map((url, index) => (
                <div key={index}>
                  <img src={url} alt={product.name} />
                </div>
              ))}
            </Carousel>

            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                <Link to={`/product/${product.id}`}>{product.name}</Link>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {product.description}
              </Typography>
              <Typography variant="body1" color="text.primary">
                ₺ {product.price}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductList;