import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, collection, getDocs, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { Product } from '../types/index';

const firebaseConfig = {
    apiKey: "AIzaSyDHzGA8w8pKFCB-ENXperzZpw3DYlgLN9s",
    authDomain: "bozcelik-d517c.firebaseapp.com",
    projectId: "bozcelik-d517c",
    storageBucket: "bozcelik-d517c.firebasestorage.app",
    messagingSenderId: "795934457870",
    appId: "1:795934457870:web:3fda53f721a4f750407c5f",
    measurementId: "G-BBCEKM5J9J"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const getProducts = async (): Promise<Product[]> => {
    const snapshot = await getDocs(collection(db, 'products'));
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Product));
};

export const addProduct = async (product: Omit<Product, 'id'>): Promise<string> => {
    const docRef = await addDoc(collection(db, 'products'), product);
    return docRef.id;
};

export const getProductById = async (id: string): Promise<Product | undefined> => {
    const docRef = doc(db, 'products', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() } as Product;
    } else {
        return undefined;
    }
};

export const updateProduct = async (id: string, product: Partial<Product>): Promise<void> => {
    const docRef = doc(db, 'products', id);
    await updateDoc(docRef, product);
  };
  
  export const deleteProduct = async (id: string): Promise<void> => {
    const docRef = doc(db, 'products', id);
    await deleteDoc(docRef);
  };