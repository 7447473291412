import React from 'react';
import ProductList from '../components/ProductList';
import Footer from '../components/Footer';
import { Box, Typography } from '@mui/material';

const HomePage: React.FC = () => {
    return (
        <div>
            <Box
                sx={{
                    py: 4,
                    backgroundImage: 'url(bg-image-1.webp)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: 'white',
                    textAlign: 'center',
                }}
            >
                <Typography variant="h2" component="div">
                    Bozçelik Para Kasaları
                </Typography>
                <Typography variant="h4" component="div">
                     Değerlerin Güvenle Saklandığı Yer
                </Typography>
            </Box>
            <Box component="section" sx={{ py: 4 }}>
                <ProductList />
            </Box>
            <Footer />
        </div>
    );
};

export default HomePage;