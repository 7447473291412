import React from 'react';
import { useParams } from 'react-router-dom';
import ProductDetails from '../components/ProductDetails';

const ProductPage: React.FC = () => {
    const { id } = useParams<{ id: string }>() as { id: string };

    return (
        <div>
            <h1>Product Details</h1>
            <ProductDetails id={id} />
        </div>
    ); 
};

export default ProductPage;