import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer: React.FC = () => {
    return (
        <Box sx={{ mt: 4 }}>
            <Box
                sx={{
                    py: 4,
                    backgroundImage: 'url(bg-image-1.webp)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: 'white',
                    textAlign: 'center',
                }}
            >
                <Typography variant="h4" component="div">
                    Bozcelik Para Kasaları
                </Typography>

                <Typography variant="h5" component="div">
                    İletişim
                </Typography>
            </Box>
            <Box sx={{ py: 2, backgroundColor: '#f5f5f5', textAlign: 'center' }}>
                <Typography variant="body1" component="div">
                    Emek, 2180. Sk. No:16, 07060 Kepez/Antalya
                </Typography>
                <Typography variant="body1" component="div">
                    0542 367 16 98
                </Typography>
                <Typography variant="body1" component="div">
                    Email: info@bozcelikparakasalari.com
                </Typography>
            </Box>
        </Box>
    );
};

export default Footer;