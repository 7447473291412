import React from 'react';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ProductPage from './pages/ProductPage';
import AdminPage from './pages/AdminPage';
import Login from './components/Login';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={HomePage} />
        <Route path="/product/:id" Component={ProductPage} />
        <Route path="/admin" Component={AdminPage} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
};

export default App;